import {
  title,
  subtitle,
} from "assets/jss/styles.js";

const houseStyle = theme => ({
  title: {
    ...title,
    fontSize: "2.5rem",
    textAlign: "center"
  },
  subtitle: {
    ...subtitle,
    fontSize: "1.5rem",
    textAlign: "center"
  },
  plano: {
    maxWidth: "800px",
    margin: "50px 0 80px"
  }
});

export default houseStyle;