import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const style = {
  breadcrumb: {
    marginBottom: "30px",
    "& a": {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: ".8rem"
    },
    "& span": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: ".8rem"
    }
  },
};

const useStyles = makeStyles(style)

const BreadcrumbsComponent = ({ links, presentPath }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      {links.map(link => {
        return (
          <Link key={link.link} to={link.link}>
            {link.text}
          </Link>
        )
      })}
      <span>{presentPath}</span>
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent
