import React from 'react'
import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Layout from 'layout/layout'
import ChargerItem from 'components/ChargerItem/ChargerItem'
import SectionPricing from 'pages-sections/construction/SectionPricing'
import style from 'assets/jss/styles/components/houseStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import Img from 'gatsby-image';
import { CARGADORES } from 'constants/routes';

const useStyles = makeStyles(style)

const ContainerHouses = ({ data, pageContext }) => {
  const classes = useStyles()
  const obj = data.cargadoresJson.chargers.find(charger => charger.slug === pageContext.slug);
  
  return (
    <Layout
      pageTitle={obj.title}
      pageDescription={obj.description}
      filter="transparent"
      noHeader
    >
      <BreadcrumbsComponent
        links={[
            {link: CARGADORES, text: "Cargadores"}
        ]}
        presentPath={obj.slug.replace(/-/g, " ")}
      />

      <h1 className={classes.title}>{obj.title}</h1>
      <h2 className={classes.subtitle}>{obj.description}</h2>

      <ChargerItem 
        data={obj}
      />
          
    </Layout>
  )
}

export default ContainerHouses

export const query = graphql`
  query ChargerQuery($slug: String) {
    cargadoresJson(chargers: {elemMatch: {slug: {eq: $slug}}}) {
      chargers {
        title
        description
        text {
          childMarkdownRemark {
            html
          }
        }
        alt
        price
        slug
        images {
          childImageSharp {
            fixed(height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;